export default {
    computed: {
        loggedUser () {
            return this.$store.state.main.auth.user
        }
    },

    methods: {
        userHasPermission (perm) {
            return this.loggedUser && this.loggedUser.Permissions && this.loggedUser.Permissions.indexOf(perm) >= 0
        }

    }
}
