var self = {
  getPaginationFromDataTableOptions(o) {
    return {
      Offset: (o.page - 1) * o.itemsPerPage,
      Limit: o.itemsPerPage,
      Sort: o.sortBy.map((field, fieldIdx) => (o.sortDesc[fieldIdx] ? "-" : "") + field),
    };
  },

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },

  equals(a, b) {
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== "object" && typeof b !== "object")) return a === b;
    if (a.prototype !== b.prototype) return false;
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every((k) => this.equals(a[k], b[k]));
  },
};

export default self;



export var isInteger = v => (Number.isInteger(parseFloat(v)))
export var isPositive = v => (parseFloat(v) > 0)
export var isPositiveOrZero = v => (parseFloat(v) >= 0)
export var isNumeric = v => (!isNaN(v) && !isNaN(parseFloat(v)))
export var isValidEmail = v => /.+@.+\..+/.test(v)