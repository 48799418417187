import './common'
import axios from 'axios'

export default {
    getTimeslots: (params) => axios.get('/access-control/timeslots', { params }),
    createTimeslots: (data) => axios.post('/access-control/timeslots', data),
    updateTimeslots: (data) => axios.put(`/access-control/timeslots/${data.ID}`, data),
    deleteTimeslot: (id) => axios.delete(`/access-control/timeslots/${id}`),
    getTimeslotGroup: (id) => axios.get(`/access-control/timeslots-groups/${id}`),
    updateTimeslotGroup: (data) => axios.put(`/access-control/timeslots-groups/${data.GroupID}`, data),
    deleteTimeslotGroup: (id) => axios.delete(`/access-control/timeslots-groups/${id}`),
    getTimeslotTemplates: (params) => axios.get('/access-control/timeslots-templates', { params }),
    createTimeslotTemplate: (data) => axios.post('/access-control/timeslots-templates', data),
    updateTimeslotTemplate: (data) => axios.put(`/access-control/timeslots-templates/${data.ID}`, data),
    deleteTimeslotTemplate: (id) => axios.delete(`/access-control/timeslots-templates/${id}`),
    postRefreshFamilies: () => axios.post('/access-control/timeslots-refresh'),
    getScan: (id) => axios.get(`/access-control/scan/${id}`),
    postScanFlash: (id, data) => axios.post(`/access-control/scan/${id}/flash`, data),
    getPenalties: (params) => axios.get('/access-control/penalties', { params }),
    recreditPenaltyPassing: (data) => axios.post('/access-control/recredit-penalty', data)
}
