export default [
    {
        path: '/cashregister',
        name: 'cashregister-index',
        meta: {
            title: 'Encaissements'
        },
        redirect: { name: 'exports' },

        component: () => import('~/views/cashregister/Index.vue'),
        children: [
            {
                path: 'exports',
                name: 'exports',
                meta: {
                    title: 'Exports de données'
                },
                component: () => import('~/views/cashregister/Exports.vue')
            },
            {
                path: 'gift-manager',
                name: 'gift-manager',
                meta: {
                    title: 'Coupons et bons cadeaux'
                },
                component: () => import('~/views/cashregister/giftmanager/Index.vue')
            },
            {
                path: 'gift-manager/plancha',
                name: 'gift-manager-plancha',
                meta: {
                    title: 'Mes planches de coupons'
                },
                component: () => import('~/views/cashregister/giftmanager/plancha/Index.vue')
            },
            {
                path: 'gift-manager/plancha/:id',
                name: 'gift-manager-plancha-create',
                meta: {
                    title: 'Créer une planche de coupons'
                },
                component: () => import('~/views/cashregister/giftmanager/plancha/Create.vue')
            }
        ]
    }
]
