export default [
    {
        path: '/turnstiles',
        name: 'turnstiles-index',
        meta: {
            title: 'Tourniquets'
        },
        redirect: { name: 'turnstiles-status' },
        component: () => import('~/views/turnstiles/Index.vue'),
        children: [
            {
                path: 'scan',
                name: 'turnstiles-scan',
                meta: {
                    title: 'Scan de billets'
                },
                component: () => import('~/views/turnstiles/Scan.vue')
            },
            {
                path: 'status',
                name: 'turnstiles-status',
                meta: {
                    title: 'Etat des terminaux'
                },
                component: () => import('~/views/turnstiles/Status.vue')
            },
            {
                path: 'planning',
                name: 'turnstiles-planning',
                meta: {
                    title: 'Planning d\'accès'
                },
                component: () => import('~/views/turnstiles/Planning.vue')
            },
            {
                path: 'history',
                name: 'turnstiles-history',
                meta: {
                    title: 'Historique de passage'
                },
                component: () => import('~/views/turnstiles/History.vue')
            },
            {
                path: 'penalties',
                name: 'turnstiles-penalties',
                meta: {
                    title: 'Pénalités'
                },
                component: () => import('~/views/turnstiles/Penalties.vue')
            },
            {
                path: 'weeks',
                name: 'turnstiles-weeks',
                meta: {
                    title: 'Types de semaines'
                },
                component: () => import('~/views/turnstiles/Weeks.vue')
            }
        ]
    }
]
