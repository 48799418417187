import Consts from '../consts'

const bookingStatuses = [
    { label: 'Sur liste d\'attente', value: Consts.BOOKING_STATUS_WAITING, icon: 'mdi-clock-time-four-outline', color: 'grey' },
    { label: 'En attente', value: Consts.BOOKING_STATUS_PENDING, icon: 'mdi-clock-time-four-outline', color: '#d68800' },
    { label: 'Validée', value: Consts.BOOKING_STATUS_VALIDATED, icon: 'mdi-check', color: '#2bc700' },
    { label: 'Annulée', value: Consts.BOOKING_STATUS_CANCELLED, icon: 'mdi-cancel', color: '#c72400' }
]

const bookingRepeatStatuses = [
    { label: 'Brouillon', value: Consts.BOOKING_SLOT_REPEAT_STATUS_DRAFT, color: "grey" },
    { label: 'Activé', value: Consts.BOOKING_SLOT_REPEAT_STATUS_VALID, color: "green" },
    { label: 'Supprimé', value: Consts.BOOKING_SLOT_REPEAT_STATUS_DELETED, color: "red" },
]

const bookingSlotWebModes = [
    { value: Consts.BOOKING_SLOT_WEB_MODE_UNLIMITED, label: 'Sans limite' },
    { value: Consts.BOOKING_SLOT_WEB_MODE_ZERO, label: 'Back-office uniquement' },
    { value: Consts.BOOKING_SLOT_WEB_MODE_SPECIFIC, label: 'Limite fixée' }
]

const bookingSlotRepeatPublicationStatuses = [
    { value: Consts.BOOKING_SLOT_REPEAT_WEB_PUBLISHED_NONE, label: 'Visible en back office et non réservable', color: 'error' },
    { value: Consts.BOOKING_SLOT_REPEAT_WEB_PUBLISHED_INFO, label: 'Visible en ligne mais non réservable', color: 'warning' },
    { value: Consts.BOOKING_SLOT_REPEAT_WEB_PUBLISHED_BOOK, label: 'Visible et réservable', color: 'success' }
]

export default {
    bookingSource(x) {
        switch (x) {
            case Consts.BOOKING_SOURCE_WEB: return { icon: 'mdi-web', label: 'Réservation par le Web' }
            case Consts.BOOKING_SOURCE_CASHREGISTER: return { icon: 'mdi-cash-register', label: 'Réservation par la caisse' }
            case Consts.BOOKING_SOURCE_MOBILE: return { icon: 'mdi-cellphone-android', label: 'Réservation par l\'application mobile' }
            case Consts.BOOKING_SOURCE_KIOSK: return { icon: 'mdi-monitor-screenshot', label: 'Réservation par la borne' }
            default: return { icon: 'mdi-help', label: 'Inconnue' }
        }
    },

    bookingStatus(v) {
        return bookingStatuses.find(s => s.value == v)
    },

    bookingRepeatStatus(v) {
        return bookingRepeatStatuses.find(s => s.value == v)
    },

    bookingSlotWebMode(v) {
        return bookingSlotWebModes.find(s => s.value == v)
    },

    bookingSlotRepeatPublicationStatus(v) {
        return bookingSlotRepeatPublicationStatuses.find(s => s.value == v)
    },
}

export { bookingStatuses , bookingRepeatStatuses, bookingSlotWebModes, bookingSlotRepeatPublicationStatuses}
