import './common'
import axios from 'axios'

export default {
    // gateways
    getLockerGateways: () => axios.get('/lockers/locker-gateways'),
    putLockerGateways: (id, data) => axios.put(`/lockers/locker-gateway/${id}`, data),

    // lockers
    getLockers: () => axios.get('/lockers/lockers'),
    getLocker: (id) => axios.get(`/lockers/locker/${id}`),
    getUnassociatedLockers: () => axios.get('/lockers/unassociated'),
    putLocker: (id, data) => axios.put(`/lockers/locker/${id}`, data),
    putLockerSettings: (id, data) => axios.put(`lockers/settings/${id}`, data),
    deleteLocker: (id) => axios.delete(`/lockers/locker/${id}`),

    // lockers actions
    changeAreaLockersState: (data) => axios.post('/lockers/area/change-state', data),
    changeBlockLockersState: (data) => axios.post('/lockers/block/change-state', data),
    changeLockersState: (data) => axios.post('/lockers/lockers/change-state', data),
    changeLockerState: (id, state) => axios.post(`/lockers/locker/${id}/change-state?state=` + state),
    changeLockersConfig: (id, data) => axios.post(`/lockers/locker/${id}/change-config`, data),
    associateLocker: (data) => axios.post('/lockers/locker/associate', data),
    dissociateLocker: (id) => axios.post(`/lockers/locker/${id}/dissociate`),
    changeLockerPreparation: (id, data) => axios.post(`/lockers/locker/${id}/preparation`, data),
    putLockersMastercode: (data) => axios.put('/lockers/change-mastercode', data),

    // lockers's types
    getLockersTypes: () => axios.get('/lockers/types'),
    getLockersType: (id) => axios.get(`/lockers/type/${id}`),
    postLockersType: (data) => axios.post('/lockers/type', data),
    putLockersType: (id, data) => axios.put(`/lockers/type/${id}`, data),
    deleteLockersType: (id) => axios.delete(`/lockers/type/${id}`),

    // lockers's blocs
    getLockersBlocks: (params) => axios.get('/lockers/blocks', { params: params }),
    getLockersBlock: (id) => axios.get(`/lockers/block/${id}`),
    postLockersBlock: (data) => axios.post('/lockers/block', data),
    putLockersBlock: (id, data) => axios.put(`/lockers/block/${id}`, data),
    deleteLockersBlock: (id) => axios.delete(`/lockers/block/${id}`),
    putLockersBlockSettings: (id, data) => axios.put(`/lockers/block-settings/${id}`, data),

    // lockers's bookings
    getLockerBookings: (params) => axios.get('/lockers/bookings', { params: params }),
    getLockerBooking: (id) => axios.get(`/lockers/bookings/${id}`),
    putLockerBooking: (id, data) => axios.put(`/lockers/bookings/${id}`, data),
    getLockerBookingIncidents: (bookingId) => axios.get(`/lockers/bookings/${bookingId}/incidents`),
    checkAttributedLockersAllOpened: (bookingId) => axios.get(`/lockers/bookings/${bookingId}/check-lockers-opened`),

    // lockers's bookings actions
    newLockerBooking: (data) => axios.post('/lockers/bookings', data),
    stopLockerBooking: (id) => axios.post(`/lockers/bookings/${id}/stop`),
    cancelLockerBooking: (id) => axios.post(`/lockers/bookings/${id}/cancel`),
    retryLockerAttribution: (bookingId) => axios.post(`/lockers/bookings/${bookingId}/retry-attribution`),

    // locker's incidents
    getLockersIncidents: (params) => axios.get('/lockers/incidents', { params: params }),
    putLockersIncidents: (id, data) => axios.put(`/lockers/incidents/${id}`, data),

    // locker's events
    getLockersEvents: (params) => axios.get('/lockers/events', { params: params }),
    refreshHistory: (params) => axios.get('/lockers/events/refresh-history', { params: params }),

    // locker's global params
    putLockersGlobalSettings: (data) => axios.put('/lockers/global-settings', data),

    // Locations
    getEstablishmentWithLockersStats: (id) => axios.get(`/lockers/establishment-with-stats/${id}`),
    getEstablishmentsWithLockersStats: () => axios.get('/lockers/establishments-with-stats'),
    getEstablishmentsWithAffectableLockers: (params) => axios.get('lockers/establishments-with-affectable', { params: params }),

    getAreaWithLockersStats: (id) => axios.get(`/lockers/area-with-stats/${id}`),
    getAreasWithLockersStats: () => axios.get('/lockers/areas-with-stats'),
    getAreasWithAffectableLockers: (params) => axios.get('lockers/areas-with-affectable', { params: params }),

    getBlockWithLockersStats: (id) => axios.get(`/lockers/block-with-stats/${id}`),
    getBlocksWithLockersStats: () => axios.get('/lockers/blocks-with-stats'),
    getBlocksWithAffectableLockers: (params) => axios.get('lockers/blocks-with-affectable', { params: params })
}
