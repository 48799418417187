export default [
    {
        path: '/webview/scan-voucher',
        name: 'voucher-scan-webview',
        meta: {
            disableDashboardLayout: true
        },
        component: () => import('~/views/webviews/VoucherScanDialog.vue')
    },
    {
        path: '/webview/periodic-registration',
        name: 'periodic-registration-webview',
        meta: {
            disableDashboardLayout: true
        },
        component: () => import('~/views/webviews/PeriodicRegistrationDialog.vue')
    },
    {
        path: '/webview/odeon',
        name: 'webbiew-odeon',
        meta: {
            disableDashboardLayout: true
        },
        component: () => import('~/views/webviews/OdeonPopup.vue')
    }
]
