export default [
    {
        path: '/setup',
        name: 'setup',
        meta: {
            title: 'Configuration'
        },
        redirect: { name: 'setup-main' },
        component: () => import('~/views/setup/Index.vue'),
        children: [
            {
                path: 'main',
                name: 'setup-main',
                meta: {
                    title: 'Paramètres généraux'
                },
                component: () => import('~/views/setup/Main.vue')
            },
            {
                path: 'establishments',
                name: 'setup-establishments',
                meta: {
                    title: 'Etablissements'
                },
                component: () => import('~/views/setup/establishments/Index.vue')
            },
            {
                path: 'establishments/new',
                name: 'setup-establishments-new',
                meta: {
                    title: 'Création de l\'établissement',
                    new: true
                },
                component: () => import('~/views/setup/establishments/Edit.vue')
            },
            {
                path: 'establishments/:id',
                name: 'setup-establishments-edit',
                meta: {
                    title: 'Configuration de l\'établissement',
                    new: false
                },
                component: () => import('~/views/setup/establishments/Edit.vue')
            },
            {
                path: 'establishments/:id/map',
                name: 'setup-establishments-edit-map',
                meta: {
                    title: 'Plan 3D de l\'établissement',
                    new: false
                },
                component: () => import('~/views/setup/establishments/EditMap.vue')
            },
            {
                path: 'mailer',
                name: 'setup-mailer',
                meta: {
                    title: 'Configuration e-mail'
                },
                component: () => import('~/views/setup/Mailer.vue')
            },
            {
                path: 'smser',
                name: 'setup-smser',
                meta: {
                    title: 'Configuration SMS'
                },
                component: () => import('~/views/setup/SMSer.vue')
            },
            {
                path: 'front-office',
                name: 'setup-front-office',
                meta: {
                    title: 'Configuration du front office'
                },
                component: () => import('~/views/setup/front/Main.vue')
            },
            {
                path: 'front-office/payments',
                name: 'setup-front-office-payments',
                meta: {
                    title: 'Configuration des paiements front office'
                },
                component: () => import('~/views/setup/front/Payments.vue')
            },
            {
                path: 'templates',
                name: 'setup-templates',
                meta: {
                    title: 'Modèles éditables'
                },
                component: () => import('~/views/setup/templates/Index.vue')
            },
            {
                path: 'documents',
                name: 'setup-documents',
                meta: {
                    title: 'Documents'
                },
                component: () => import('~/views/setup/Documents.vue')
            },
            {
                path: 'templates/new',
                name: 'setup-templates-new',
                meta: {
                    title: 'Nouveau modèle'
                },
                component: () => import('~/views/setup/templates/Edit.vue')
            },
            {
                path: 'templates/:id',
                name: 'setup-templates-edit',
                meta: {
                    title: 'Edition d\'un modèle'
                },
                component: () => import('~/views/setup/templates/Edit.vue')
            },
            {
                path: 'planning',
                name: 'setup-planning',
                meta: {
                    title: 'Configuration du planning'
                },
                component: () => import('~/views/setup/Planning.vue')
            },
            {
                path: 'turnstiles',
                name: 'setup-turnstiles',
                meta: {
                    title: 'Configuration des tourniquets'
                },
                component: () => import('~/views/setup/Turnstiles.vue')
            },
            {
                path: 'lockers-global',
                name: 'setup-lockers-global',
                meta: {
                    title: 'Gestion des casiers'
                },
                component: () => import('~/views/setup/LockersGlobal.vue')
            },
            {
                path: 'lockers-params',
                name: 'setup-lockers-params',
                meta: {
                    title: 'Paramètres des casiers'
                },
                component: () => import('~/views/setup/LockersParams.vue')
            },
            {
                path: 'lockers-booking',
                name: 'setup-lockers-booking',
                meta: {
                    title: 'Gestion des réservations'
                },
                component: () => import('~/views/setup/LockersBookings.vue')
            },
            {
                path: 'spa/universe',
                name: 'setup-spa',
                meta: {
                    title: 'Configuration Spa'
                },
                component: () => import('~/views/setup/spa/Index.vue')
            },
            {
                path: 'spa/universe/:id',
                name: 'setup-universe-edit',
                meta: {
                    title: 'Configuration d\'un univers Spa'
                },
                component: () => import('~/views/setup/spa/UniverseEdit.vue')
            },
            {
                path: 'spa/universe/:id/params',
                name: 'setup-universe-params',
                meta: {
                    title: 'Configuration de l\'univers'
                },
                component: () => import('~/views/setup/establishments/Edit.vue')
            },
            {
                path: 'areas',
                name: 'setup-areas',
                meta: {
                    title: 'Configuration des zones'
                },
                component: () => import('~/views/setup/Areas.vue')
            },
            {
                path: 'stats',
                name: 'setup-stats',
                meta: {
                    title: 'Configuration des statistiques'
                },
                component: () => import('~/views/setup/Statistiques.vue')
            }
        ]
    }
]
