import moment from 'moment'

moment.locale('fr')

export default {
    formatDate(d, format = 'DD/MM/YYYY') {
        return moment(d).format(format)
    },
    changeDateFormat(d, fIn, fOut) {
        return moment(d, fIn).format(fOut)
    },
    formatDateAPI(d){
        return moment(d).format()
    },
    formatDateUS(d) {
        return moment(d).format('YYYY-MM-DD')
    },
    formatBirthDate(date) {
        const parsedDate = moment(date)
        if (!parsedDate.isValid() || parsedDate.year() <= 1901) {
            return ''
        } else {
            return parsedDate.format('DD/MM/YYYY')
        }
    },
    isDateFormatValid(date,format){
        return moment(date,format, true).isValid()
    },
    formatDateTime(d) {
        return moment(d).format('DD/MM/YYYY HH:mm')
    },
    formatDateTimeFile(d) {
        return moment(d).format('DD.MM.YYYY-HH:mm')
    },
    formatDateTimeSeconds(d) {
        return moment(d).format('DD/MM/YYYY HH:mm:ss')
    },
    formatDateHuman(d) {
        return moment(d).format('ddd DD MMM YYYY')
    },
    formatDateWeek(d) {
        return moment(d).format('MMM YYYY ([S]WW)')
    },
    formatHumanBirthDate(date) {
        const parsedDate = moment(date)
        if (!parsedDate.isValid() || parsedDate.year() <= 1901) {
            return ''
        } else {
            return this.formatDateHuman(date)
        }
    },
    formatDateTimeHuman(d) {
        return moment(d).format('ddd DD MMM YYYY, HH:mm')
    },
    formatTime(d) {
        return moment(d).format('HH:mm')
    },
    formatTimeSecs(d) {
        return moment(d).format('HH:mm:ss')
    },
    formatHourShort(d) {
        return moment(d).format('HH')+'h'
    },
    formatWeekDay(d) {
        return moment(d).format('dddd')
    },
    formatWeekNumber(d) {
        return moment(d).format('W')
    },
    parseDate(d) {
        const date = moment(d, 'DD/MM/YYYY')
        if (date.isValid()) {
            return date.format('YYYY-MM-DD')
        } else {
            return ''
        }
    },
    addToDateTime(d, unit, type) {
        return moment(d).add(unit, type)
    },
    prevDay(d) {
        return moment(d).subtract(1, 'days').format('YYYY-MM-DD')
    },
    nextDay(d) {
        return moment(d).add(1, 'days').format('YYYY-MM-DD')
    },
    prevWeek(d) {
        return moment(d).subtract(1, 'week').format('YYYY-MM-DD')
    },
    nextWeek(d) {
        return moment(d).add(1, 'week').format('YYYY-MM-DD')
    },
    nextMonth(d) {
        return moment(d).add(1, 'month').format('YYYY-MM-DD')
    },
    isThisHour(hour) {
        return parseInt(moment().format('HH'), 10) === parseInt(hour, 10)
    },
    isToday(date) {
        return moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    },
    isAfter(d1, d2) {
        return moment(d1).isAfter(moment(d2))
    },
    isBefore(d1, d2) {
        return moment(d1).isBefore(moment(d2))
    },
    timeNow() {
        return moment().format('HH:mm')
    },
    dateNow () {
        return moment().format('YYYY-MM-DD')
    },
    datetimeNow() {
        return moment().format('DD/MM/YYYY HH:mm:ss')
    },
    isFuture(d) {
        return moment(d).isSameOrAfter(moment())
    },
    isPast(d) {
        return moment(d).isBefore(moment())
    },
    compareDate(d1, d2) {
        return moment(d1).isSame(moment(d2)) ? 0 : moment(d1).isBefore(moment(d2)) ? -1 : 1
    },
    compareDateDesc(d1, d2) {
        return moment(d1).isSame(moment(d2)) ? 0 : moment(d1).isAfter(moment(d2)) ? -1 : 1
    },
    getAge(date) {
        const parsedDate = moment(date)
        if (!parsedDate.isValid() || parsedDate.year() <= 1901) {
            return ''
        } else {
            return moment().diff(parsedDate, 'years')
        }
    },
    getGolangWeekday(date) {
        const isoWeekday = moment(date).isoWeekday()
        switch (isoWeekday) {
            case 7: return 0 // In Golang, Sunday is 0 - Saturday 6
            default: return isoWeekday
        }
    },
    getWeekdayFromGolang(weekday) {
        switch (weekday) {
            case 0: return 7 // see getGolangWeekday
            default: return weekday
        }
    },
    getWeekNumber(date) {
        return moment(date).week()
    },
    getMonthLabel(m) {
        return moment.months()[m-1]
    },
    getMonthShortLabel(m) {
        return moment.monthsShort()[m-1]
    },
    formatDay(date){
        return moment(date).format('dddd')
    },
    getDateDay(date, format){
        return moment(date, format).day()
    },
    getDateMonth(date, format){
        return moment(date, format).month()
    },
    isZeroDate(d){
        return d == null || moment(d).format('YYYY-MM-DD') == '0001-01-01'
    },
    isSameDay(d1, d2){
        return moment(d1).format('YYYY-MM-DD') === moment(d2).format('YYYY-MM-DD')
    },
    getDateAtWeekdayAfter(dateRef, weekday) {
        dateRef = moment(dateRef)
        let refDateWeekday =  dateRef.weekday()
    
        let daysToAdd = 0
        if (refDateWeekday < weekday) {
            daysToAdd = weekday - refDateWeekday
        } else if (refDateWeekday > weekday) {
            daysToAdd = 7 - (refDateWeekday - weekday)
        }
    
        return dateRef.add(daysToAdd, 'days').format()
    },
    
    getDateAtWeekdayBefore(dateRef, weekday) {
        dateRef = moment(dateRef)
        let refDateWeekday = dateRef.weekday()
    
        let daysToSub = 0
        if (refDateWeekday > weekday) {
            daysToSub = refDateWeekday - weekday
        } else if (refDateWeekday < weekday) {
            // we must slice a week
            daysToSub = 7 - (weekday + refDateWeekday)
        }
    
        return dateRef.add(-daysToSub, 'days')
    }
    
}
