import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import fr from 'vuetify/es5/locale/fr'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
    directives: {
        Ripple
    }
})

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#42a5f5',
                secondary: '#ff9100',
                accent: '#ff9100',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            }
        }
    },
    customProperties: true,
    icons: {
        iconfont: 'mdi'
    },
    lang: {
        locales: { fr },
        current: 'fr'
    }
})
