import './common'
import axios from 'axios'

export default {
    getActivities: (params) => axios.get('/activities', { params: params }),
    getActivity: (id) => axios.get(`/activities/${id}`),
    getActivitiesFamilies: (params) => axios.get('/activities-families', { params: params }),
    getLevels: (params) => axios.get('/levels', { params: params })

}
