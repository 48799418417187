import Consts from '../consts'
import moment from 'moment'

const TURNSTILE_STATE_OFFLINE = "OFFLINE";
const TURNSTILE_STATE_READY = "READY";
const TURNSTILE_STATE_ERROR = "ERROR";


const turnstilesTypes = [
    { label: 'Autre', value: 0 },
    { label: 'Lecteur RFID (tourniquet)', value: 1, icons: ['mdi-turnstile', 'mdi-nfc'] },
    { label: 'Lecteur QRcode (tourniquet)', value: 2, icons: ['mdi-turnstile', 'mdi-qrcode'] },
    { label: 'Lecteur RFID (porte)', value: 3, icons: ['mdi-door', 'mdi-nfc'] },
    { label: 'Lecteur QRcode (porte)', value: 4, icons: ['mdi-door', 'mdi-qrcode'] },
    { label: 'Lecteur RFID (borne d\'information)', value: 5, icons: ['mdi-laptop', 'mdi-nfc'] },
    { label: 'Bouton poussoir', value: 6, icons: ['mdi-turnstile', 'mdi-gesture-tap-button'] }
]

const turnstilesStates = [
    { value: TURNSTILE_STATE_OFFLINE, icon: 'mdi-lightning-bolt-circle', color: '#888888' },
    { value: TURNSTILE_STATE_READY, icon: 'mdi-check-circle', color: '#41AC53' },
    { value: TURNSTILE_STATE_ERROR, icon: 'mdi-alert-circle', color: '#FF4444' }
]

const passingDirections = [
    { label: 'N/A', value: Consts.PASSING_DIRECTION_UNSET },
    { label: 'Entrée', value: Consts.PASSING_DIRECTION_IN },
    { label: 'Sortie', value: Consts.PASSING_DIRECTION_OUT },
]

const passingTypes = [
    { label: 'Lecture de badge', value: Consts.PASSING_TYPE_CARD_QUERY },
    { label: 'Passage', value: Consts.PASSING_TYPE_TURNSTILE_PASSING },
    { label: 'Sortie tardive', value: Consts.PASSING_TYPE_PUNISH_LATE_EXIT },
]

const accessControlEventTypes = [
    { label: 'Inconnu', value: Consts.ACCESS_CONTROL_EVENT_UNK },
    { label: 'Box prête', value: Consts.ACCESS_CONTROL_EVENT_BOX_READY },
    { label: 'Box en erreur', value: Consts.ACCESS_CONTROL_EVENT_BOX_ERROR },
    { label: 'Box hors-ligne', value: Consts.ACCESS_CONTROL_EVENT_BOX_OFFLINE },
    { label: 'Tourniquet prêt', value: Consts.ACCESS_CONTROL_EVENT_TS_READY },
    { label: 'Tourniquet en erreur', value: Consts.ACCESS_CONTROL_EVENT_TS_ERROR },
    { label: 'Tourniquet hors-ligne', value: Consts.ACCESS_CONTROL_EVENT_TS_OFFLINE },
    { label: 'Carte inconnue', value: Consts.ACCESS_CONTROL_EVENT_UNK_CARD },
    { label: 'Passage autorisé', value: Consts.ACCESS_CONTROL_EVENT_PASSING_ALLOW },
    { label: 'Passage refusé', value: Consts.ACCESS_CONTROL_EVENT_PASSING_DENIED },
    { label: 'Temps de passage dépassé', value: Consts.ACCESS_CONTROL_EVENT_PASSING_TIMEOUT },
    { label: 'Passage réalisé', value: Consts.ACCESS_CONTROL_EVENT_PASSING_CONFIRM },
    { label: 'Message de la box', value: Consts.ACCESS_CONTROL_EVENT_BOX_LOG }
]

export default {
    turnstilesState(state, parentState) {
        if (!parentState || parentState !== TURNSTILE_STATE_OFFLINE) {
            return turnstilesStates.find(s => s.value == state)
        } else {
            return { icon: 'mdi-help-circle', color: '#888888' }
        }
    },

    turnstyleType(v) {
        return turnstilesTypes.find(t => t.value == v)
    },

    passingDirection(v) {
        return passingDirections.find(d => d.value == v)
    },

    passingType(t) {
        return passingTypes.find(p => p.value == t)
    },

    accessControlEventType(x) {
        return accessControlEventTypes.find(t => t.value == x)
    },

    isPassingDateValid (date) {
        if (!date) {
            return false
        }
        const mm = moment(date)
        return mm.isValid() && mm.isAfter(moment('2005-01-01'))
    }
}

export { turnstilesTypes, passingTypes, passingDirections, turnstilesStates, accessControlEventTypes }