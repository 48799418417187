export default {
    // WS RESOURCE
    WS_RESOURCE_TURNSTILE_INFO: "r://turnstile-info",
    WS_RESOURCE_TURNSTILE_HISTORY: "r://turnstile-history",
    WS_RESOURCE_ACCESS_CONTROL_VOUCHERS: "r://access-control-vouchers",
    WS_RESOURCE_CLIENTS: "r://clients",
    WS_RESOURCE_SPA_BOOKINGS: "r://spa-bookings",
    WS_RESOURCE_AREA_ATTENDANCE: "r://area-attendance",
    WS_RESOURCE_ESTABLISHMENTS: "r://establishments",
    WS_RESOURCE_AREAS: "r://areas",
    WS_RESOURCE_LOCKERS: "r://lockers",
    WS_RESOURCE_LOCKERS_STATE: "r://locker-action/state",
    WS_RESOURCE_LOCKERS_CONFIG: "r://locker-action/config",
    WS_RESOURCE_LOCKERS_HISTORY: "r://locker-action/history",
    WS_RESOURCE_LOCKER_BLOCKS: "r://locker-blocks",
    WS_RESOURCE_LOCKER_GATEWAYS: "r://locker-gateways",
    WS_RESOURCE_LOCKER_BOOKINGS: "r://locker-bookings",
    WS_RESOURCE_LOCKER_BOOKINGS_NEW: "r://locker-bookings-new",
    WS_RESOURCE_LOCKER_BOOKINGS_STOP: "r://locker-bookings-action/stop",
    WS_RESOURCE_LOCKER_BOOKINGS_CANCEL: "r://locker-bookings-action/cancel",
    WS_RESOURCE_LOCKER_INCIDENTS_NEW: "r://locker-incidents-new",
    WS_RESOURCE_LOCKER_INCIDENTS_UPDATE: "r://locker-incidents-update",
    WS_RESOURCE_INDOOR_POSITIONING: "r://indoor-pos",
    WS_RESOURCE_KIOVID: "r://kiovid",
    WS_RESOURCE_DISPLAYS: "r://displays",
    WS_RESOURCE_VOUCHERSHEET_PROGRESS: "r://vouchersheet-progress",

    // ACCESS_CONTROL
    ACCESS_CONTROL_EVENT_UNK: 0,
    ACCESS_CONTROL_EVENT_BOX_READY: 1,
    ACCESS_CONTROL_EVENT_BOX_ERROR: 2,
    ACCESS_CONTROL_EVENT_BOX_OFFLINE: 3,
    ACCESS_CONTROL_EVENT_TS_READY: 4,
    ACCESS_CONTROL_EVENT_TS_ERROR: 5,
    ACCESS_CONTROL_EVENT_TS_OFFLINE: 6,
    ACCESS_CONTROL_EVENT_UNK_CARD: 7,
    ACCESS_CONTROL_EVENT_PASSING_ALLOW: 8,
    ACCESS_CONTROL_EVENT_PASSING_DENIED: 9,
    ACCESS_CONTROL_EVENT_PASSING_TIMEOUT: 10,
    ACCESS_CONTROL_EVENT_PASSING_CONFIRM: 11,
    ACCESS_CONTROL_EVENT_BOX_LOG: 12,

    PASSING_TYPE_CARD_QUERY: 1,
    PASSING_TYPE_TURNSTILE_PASSING: 2,
    PASSING_TYPE_PUNISH_LATE_EXIT: 3,

    PASSING_DIRECTION_UNSET: 0,
    PASSING_DIRECTION_IN: 1,
    PASSING_DIRECTION_OUT: 2,

    // BOOKINGS
    BOOKING_SOURCE_WEB: 0,
    BOOKING_SOURCE_CASHREGISTER: 1,
    BOOKING_SOURCE_MOBILE: 2,
    BOOKING_SOURCE_KIOSK: 3,

    BOOKING_STATUS_PENDING: 0,
    BOOKING_STATUS_VALIDATED: 1,
    BOOKING_STATUS_CANCELLED: 2,
    BOOKING_STATUS_WAITING: 3,

    BOOKING_SLOT_WEB_MODE_ZERO: 0,
    BOOKING_SLOT_WEB_MODE_UNLIMITED: 1,
    BOOKING_SLOT_WEB_MODE_SPECIFIC: 2,

    BOOKING_SLOT_REPEAT_WEB_PUBLISHED_NONE: 0,
    BOOKING_SLOT_REPEAT_WEB_PUBLISHED_INFO: 1,
    BOOKING_SLOT_REPEAT_WEB_PUBLISHED_BOOK: 2,

    SUBSCRIPTION_TYPE_CREDIT: 1,
    SUBSCRIPTION_TYPE_SUBSCRIPTION: 2,
    SUBSCRIPTION_TYPE_PASS_Q: 3,
    SUBSCRIPTION_TYPE_PASS_H: 4,
    SUBSCRIPTION_TYPE_PACK: 5,
    SUBSCRIPTION_TYPE_OTHER: 6,
    SUBSCRIPTION_TYPE_2DTAG: 7,

    // SPA
    SPA_PRODUCT_MODE_ALL: 1,
    SPA_PRODUCT_MODE_MULTI_OF: 2,

    SPA_NOTIFICATION_CREATE: "create",
    SPA_NOTIFICATION_UPDATE: "update",
    SPA_NOTIFICATION_DELETE: "delete",
    SPA_NOTIFICATION_REMINDER: "reminder",

    // SUBSCRIPTION RENEW OPTIONS
    RENEW_OPTIONS_USE_SUBSCRIPTION_VALIDITY_ONLY: 0,
    RENEW_OPTIONS_ADD_REMAINING_VALIDITY: 1,

    RENEW_OPTIONS_KEEP_BALANCE_ALWAYS: 0,
    RENEW_OPTIONS_KEEP_BALANCE_IF_NOT_EXPIRED: 1,
    RENEW_OPTIONS_KEEP_BALANCE_NEVER: 2,

    // PRODUCT VALIDITY TYPES
    PRODUCT_VALIDITY_SALE_DATE: 1,
    PRODUCT_VALIDITY_FIXED: 2,
    PRODUCT_VALIDITY_FIRST_USE: 3,
    PRODUCT_VALIDITY_CUSTOM_DATE: 4,
    PRODUCT_VALIDITY_MATCH_BOOKING_DATE: 5,

    // Duration types
    DURATION_PER_DAY: 1,
    DURATION_PER_MONTH: 2,
    DURATION_PER_YEAR: 3,

    // FREQUENCIES
    FREQUENCY_NEVER: 0,
    FREQUENCY_OCCASIONAL: 1,
    FREQUENCY_MONTHLY: 2,
    FREQUENCY_YEARLY: 3,

    MODE_FIRST_PURCHASE_FIX_DATES: 1,
    MODE_FIRST_PURCHASE_DATE_TO_DATE: 2,

    // SALES
    SALE_STATE_SCHEDULED: -1,
    SALE_STATE_DRAFT: 1,
    SALE_STATE_PAUSED: 2,
    SALE_STATE_PAYING: 3,
    SALE_STATE_READY_CONFIRMED: 4,
    SALE_STATE_CONFIRMED: 5,
    SALE_STATE_CANCELLED: 6,
    SALE_STATE_PAUSED_PAYING: 7,
    SALE_STATE_PAYMENT_PENDING: 8,
    SALE_STATE_ARRHES: 9,

    BOOKING_SLOT_REPEAT_STATUS_DRAFT: 0,
    BOOKING_SLOT_REPEAT_STATUS_VALID: 1,
    BOOKING_SLOT_REPEAT_STATUS_DELETED: 2,

    // CHECKMAIL STATUS
    MAIL_STATUS_OK: 1,
    MAIL_STATUS_UNDETERMINED: 2,
    MAIL_STATUS_BAD_FORMAT: 3,
    MAIL_STATUS_HOST_UNRESOLVABLE: 4,
    MAIL_STATUS_UNKNOWN_USER: 5,

    // Booking refused code
    BOOKING_IS_AUTHORIZED: 0,
    LIMIT_BOOKING_TIME_PASSED: 1,
    SLOT_OUT_OF_VISIBILITY: 2,
    NO_PRODUCT: 3,
    WRONG_CLIENT_GROUP: 4,
    SWIMING_LEVEL_NOT_GOOD: 5,
    MAX_BOOKING_PER_WEEK_REACHED: 6,
    ALREADY_BOOKED: 7,
    NO_PLACE_AVAILABLE: 8,
    NO_PLACE_AVAILABLE_WEB: 9,
    WEB_MODE_UNAVAILABLE: 10,

    // Kiovid Type
    UNKNOWN_KIOVID: -1,
    KIOSK_KIOVID: 0,
    CASHREGISTER_KIOVID: 1,

    // CLIENTS
    CLIENTS: {
        GENDERS: {
            UNKNOW: 'U',
            FEMALE: 'F',
            MALE: 'M',
        },
        STATE: {
            DISABLED: 0,
            VALID: 1,
        }
    },
    CLIENT_TYPE_PERSON: 1,      // Physical person
    CLIENT_TYPE_ORG: 2,         // Company/organization
    CLIENT_TYPE_OTHER: 3,       // Other
    CLIENT_ORG_CE:4,            //CE
    CLIENT_ORG_COLLECTIVITY:5,  //Mairies, etc
    CLIENT_ORG_GROUPING:6,      //Groupement scolaires
    CLIENT_ORG_ORGANIZATION:7,  //Organizations
    CLIENT_ORG_INDIVIDUAL:8,    //Individual
    CLIENT_ORG_PRIVATE:9,       //Private
    CLIENT_ORG_PUBLIC:10,       //Public establishment

    PRICING_DEFAULT: "DEFAULT",

    GLOBAL_PARAMS: {
        PARAM_DECIMAL_AMOUNT: 1,
        PARAM_CURRENCY_SIGN: 2,
        PARAM_ALERT_STOCK: 3,
        PARAM_THEME_DEFAULT: 4,
        PARAM_ASSOCIATION_CLIENT_AUTO: 5,
        PARAM_LANGUAGE: 6,
        PARAM_KEYPAD: 7,
        PARAM_RECEIPT_CHECKED: 8,
        PARAM_DATA_PATH: 9,
        PARAM_PRODUCT_LIST_FIRST: 10,
        PARAM_PRODUCT_FIRST_IN_SALE: 11,
        PARAM_PRODUCT_FAV: 12,
        PARAM_MULTI_CLIENT: 13,
        PARAM_POSTAL_CODE_AFTER_SALE: 14,
        PARAM_CHANGE_PRICE_ON_LINE: 15,
        PARAM_PAYMENT_LATER: 16,
        PARAM_MULTI_PAYMENT: 17,
        PARAM_CARD_ENABLE: 18,
        PARAM_CHANGE_TYPE_PAYMENT_AFTER_SALE: 19,
        PARAM_PAUSE_SALE: 20,
        PARAM_MAX_CLIENTS_IN_SALE: 21,
        PARAM_ARCHIVE_AUTO: 22,
        PARAM_KEYPAD_DISCOUNT: 23,
        PARAM_BEGIN_PERIOD: 24,
        PARAM_NBR_WRONG_CASHFUND: 25,
        PARAM_CASHBOOK_TYPE_VERIF: 26,
        PARAM_SALE_LOST: 27,
        PARAM_PRINTER_RECEIPT: 28,
        PARAM_PRINTER_BILL: 29,
        PARAM_VOUCHER: 30,
        PARAM_UNIT_POINT: 31,
        PARAM_DELAYED_PAYEMENT: 32,
        PARAM_CREDIT: 33,
        PARAM_PAYMENT_SELECT_TYPE_FIRST: 34,
        PARAM_MQTT_ENABLE: 35,
        PARAM_BROKER_ADDRESS: 36,
        PARAM_SYNCHRO_WEB: 37,
        PARAM_DEV_TOOLS: 38,
        PARAM_API_KEY: 39,
        PARAM_CENTRAL_ADDRESS: 40,
        PARAM_SQL_ADDRESS: 41,
        PARAM_USE_TPE: 42,
        PARAM_TPE_COM_PORT: 43,
        PARAM_INTERNET: 44,
        PARAM_SWITCH_CLIENT_AUTO: 45,
        PARAM_CASH_DRAWER: 46,
        PARAM_UI_ZOOM_FACTOR: 47,
        PARAM_UPDATER_ADDRESS: 48,
        PARAM_UPDATER_API_KEY: 49,
        PARAM_MESSAGES_JUSTIFICATION: 50,
        PARAM_VALIDATION_PAYMENT_AUTO: 51,
        PARAM_PAYMENT_MODE_NORMAL: 52,
        PARAM_AUTO_DECO_TIME: 53,
        PARAM_CASH_DRAWER_ENABLE: 54,
        PARAM_WIDTH_RECEIPT: 55,
        PARAM_QRCODE_MESSAGE: 56,
        PARAM_QRCODE_PRINTER: 57,
        PARAM_BARCODE_PRINTER: 58,
        PARAM_BARCODE_SIZE: 59,
        PARAM_QRCODE_PARAMS: 60,
        PARAM_COLOR_PRICINGS_PRIORITY: 61,
        PARAM_USE_EXTERNAL_DISPLAY: 62,
        PARAM_PAYMENT_DIFF_FOR_CLIENT: 63,
        PARAM_AUTOCREATE_ONLINE_ACCOUNT: 64,
        PARAM_ASK_FOR_OTHER_CARD: 65,
        PARAM_SHOW_MORE_TOOLBAR: 66,
        PARAM_SHOW_SUBS_IN_TICKET: 67,
        PARAM_PRODUCTS_IN_CASHBOOK_TICKET: 68,
        PARAM_AUTO_PRINT_CASHBOOK_TICKET: 69,
        PARAM_DIMS1_URL: 70,
        PARAM_DIMS2_URL: 71,
        PARAM_DIMS2_API_KEY: 72,
        PARAM_DENY_ASSIGNED_CARD: 73,
        PARAM_SHORT_CASHBOOK_TICKET: 74,
        PARAM_EXPORT_SKIP_AVOIRS: 75,
        PARAM_PRINT_ON_FAST_TICKET: 76,
        PARAM_EXPORT_ADD_NEW_PAGE: 77,
        PARAM_EXPORT_SKIP_DIFFERED: 78,
        PARAM_DUPLICATE_FOR_ORG: 79,
        PARAM_AUTO_PRINT_CASH_FUND_START: 80,
        PARAM_AUTO_PRINT_CASH_FUND_END: 81,
        PARAM_CLIENTS_NAME_STANDARDIZATION: 82,
        PARAM_CORRECTION_GRAND_TOTAL: 83,
        PARAM_SUB_INITIAL_CASHFUND: 84,
        PARAM_MAILMAN_ADDRESS: 85,
        PARAM_SHOW_MQTT_STATUS: 86,
        PARAM_USE_CHEQUE_READER: 87,
        PARAM_CHEQUE_READER_COM: 88,
        PARAM_SHOW_TOTAL_QTY_ON_CARD: 89,
        PARAM_REFRESH_ALL_SUBS_ON_CARD: 90,
        PARAM_CASHREGISTER_ESTABLISHMENT: 91, // DON'T USE ME, RECYLE ME !
        PARAM_BILL_SUMMARY_TOP_MARGIN: 92,
        PARAM_ARRHES: 93,
        PARAM_TVA_ON_PAYMENTS_STATS: 94,
        PARAM_SPA_BOOKINGS_ON_TRACKING: 95,
        PARAM_SHOW_PERCENTAGE_ON_EXPORT: 96,
        PARAM_TICKET_TEMPLATE: 97,
        PARAM_ACCESS_CONTROL_VOUCHER_TEMPLATE: 98,
        PARAM_ADD_MORE_INFOS_AT_SALE_END: 99,
        PARAM_SHOW_ESTABLISHMENT_LOGO_ON_TICKET: 100,
        PARAM_BILL_TEMPLATE: 101,
        PARAM_VOUCHER_TEMPLATE: 102,
        PARAM_VOUCHER_GIFT_CARD_TEMPLATE: 103,
        PARAM_VOUCHER_DISCOUNT_TEMPLATE: 104,
        PARAM_VOUCHER_WEB_GIFT_CARD_TEMPLATE: 105,
        PARAM_ENABLE_SALE_GIFT: 106,
        PARAM_HIDE_FAMILIES_IN_SALE: 107,
        PARAM_PREVENT_CREDIT_CARD_CANCEL: 108,
        PARAM_WEB_ACCESS_CONTROL_VOUCHER_TEMPLATE: 109,
        PARAM_LOCKER_DELAY_BETWEEN_BOOKINGS: 110,
        PARAM_LOCKER_GATEWAY_SCAN_LOOP_INTERVAL: 111,
        PARAM_UPDATE_IN_PLACE: 112,
        PARAM_USE_EXIT_BOXES_FOR_MANUAL_ACCESS: 113,
        PARAM_SKIP_EXTERNAL_DEFERED_IN_PRODUCTS_EXPORT: 114,
        PARAM_ENABLE_COVID_PASS: 115,
        PARAM_AUTOCREATE_PERIODS: 116,
        PARAM_EXPORT_ADD_CAGNOTTE_COLLECTIONS: 117,
        PARAM_CREDIT_VOUCHER_REMAINING_AMOUNT: 118,
        PARAM_SHOW_ALL_DEFERRED_PAYMENTS: 119,
        PARAM_ZEBRA_WB_TEMPLATE: 120,
        PARAM_ACV_ON_ZEBRA_WB: 121,
        PARAM_VOUCHER_GIFT_CARD_VALIDITY: 122,
        PARAM_CANCEL_SALE_WITH_ALL_PAYMENT_MEAN: 123,
        PARAM_PAUSE_SALE_STARTED_PAYING: 124,
        PARAM_CREDIT_UNITS_FOR_GIFTING_CLIENT: 125,
        PARAM_USE_TURNSTILE_MODE: 126,
        PARAM_ENABLE_CIVIL_REF: 127,
        PARAM_ZEBRA_ACCESS_CONTROL_VOUCHER_TEMPLATE: 129
    },

    SMS: {
        SERVICE_UNKNOWN: 'aucun service',
        ENDPOINT_UNKNOWN: 'aucune passerelle',
        OPERATOR_UNKNOWN: 0,
        OPERATOR_OVH: 1,
        OPERATORS: [
            {
                value: 0,
                text: 'aucun opérateur',
            },
            {
                value: 1,
                text: 'OVH',
            },
        ],
        BY_ACTIVITY: 0,
        BY_DAY: 1,
        GROUP_BY: [
            {
                value: 0,
                text: 'par activité',
                description: 'Un SMS par activité (potentiellement plusieurs SMS par jour).',
            },
            {
                value: 1,
                text: 'par jour',
                description: 'Un SMS par jour (rappel de toutes les activités du jour concerné).',
            },
        ],
        CONFIRMATION_STATUS: {
            NONE: 0,
            DESIRED: 1,
            NO_PHONENUMBER: 2,
            UNDELIVERED: 3,
            AWAITING: 4,
            NO_REPLY: 5,
            REPLY_YES: 6,
            REPLY_NO: 7,
            REPLY_OTHER: 8
        }
    },

    VOUCHER_TYPES: {
        CREDIT: 3,
        CREDIT_UNIT: 4
    },

    TEMPLATE_TYPES: {
        TICKET: 'ticket',
        VOUCHER: 'voucher',
        VOUCHER_WEB_GIFT_CARD: 'voucher-web-gift-card',
        VOUCHER_GIFT_CARD: 'voucher-gift-card',
        VOUCHER_DISCOUNT: 'voucher-discount',
        ACCESS_CONTROL_VOUCHER: 'access-control-voucher',
        WEB_ACCESS_CONTROL_VOUCHER: 'web-access-control-voucher',
        ZEBRA_ACCESS_CONTROL_VOUCHER: 'zebra-access-control-voucher',
        BILL: 'bill',
        RENEWABLE_SUBSCRIPTION_CONTRACT: "renewable-subscription-contract",
    },

    COMMUNICATIONS: {
        CHANNEL_KEEP_ALIVE: "keepAlive",
        CHANNEL_SUBSCRIBE: "subscribe",
        CHANNEL_UNSUBSCRIBE: "unsubscribe",
        CHANNEL_NOTIFY: "notify",
        CHANNEL_ERROR: "error",

        RESOURCE_ENVIRONMENT: "environment",
        RESOURCE_CONNECTIVITY: "connectivity",
        RESOURCE_SESSION_ID: "sessionID",
        RESOURCE_CASHREGISTER: "cashregisters",
        RESOURCE_CASHREGISTER_HEALTH: "cashregisterHealth",
        RESOURCE_CASHREGISTER_UPDATE_PROGRESS: "cashregisterUpdateProgress",
        RESOURCE_CASHREGISTER_SYNC_PROGRESS: "cashregisterSyncProgress",
        RESOURCE_CASHREGISTER_SYNC_OUT_PROGRESS: "cashregisterSyncOutProgress",
        RESOURCE_TURNSTILE_INFO: "turnstile-info",
        RESOURCE_TURNSTILE_HISTORY: "turnstile-history",
        RESOURCE_ACCESS_CONTROL_VOUCHERS: "access-control-vouchers",
        RESOURCE_CLIENTS: "clients",
        RESOURCE_SPA_BOOKINGS: "spa-bookings",
        RESOURCE_AREA_ATTENDANCE: "area-attendance",
        RESOURCE_COMPARISON_PROGRESS: "comparison-progress",
        RESOURCE_RESYNC_PROGRESS: "resync-progress",
    },

    EXPORT_TYPES: {
        EXCEL: {
            PRODUCT_SIMPLE: 1,
            PRODUCT_WITH_PRICING: 2,
            PAYMENTS: 3,
            EXCEPTED_PAYMENTS: 4,
            SALES_SIMPLE: 5,
            SALES_LINES: 6,
            SALES_PAYMENTS: 7,
            CASHFUND_SIMPLE: 8,
            CASHFUND_DETAILS: 9,
            GENERAL: 10,
            SALES_CREDIT_NOTE: 11,
            CHEQUES: 12,
            DEFERREDPAYMENTS: 13,
            DISCOUNTS: 14,
            SALES_CORRECTIONS: 15,
            JET: 16,
            STOCKS: 17,
            DOCUMENTS: 18,
            GT: 19,
            POSTAL_CODES: 20,
            PAYMENT_TYPES: 21,
            DEFERRED_PAYMENTS_BY_CLIENTS: 22,
            SALES_NOMINATIVE_PAYMENTS: 23,
            MODIFICATIONS: 24,
            DEFERRED_EXTERNAL_PAYMENTS_BY_CLIENTS: 25,
            DEFERRED_EXTERNAL_PAYMENTS: 26,
            SLIPS: 27,
            PAUSED_SUBSCRIPTIONS: 28,
            PAYMENT_TYPES_PER_DAY: 29,
        },

        PDF: {
            COLLECTION: 1,
            PRODUCTS: 2,
            FAMILIES: 3,
            PRODUCTS_BY_FAMILIES: 4,
            CLIENT_SALES: 5,
            CREDIT_CARD_PAYMENTS: 6,
            SLIPS: 7,
            HOLIDAY_VOUCHER_SLIPS: 8,
            SPORTS_COUPONS: 9,
            CREDIT_NOTES: 10,
            SPECIAL_PRICES: 11,
            DEFERRED_PAYMENTS: 12,
            DEFERRED_PAYMENTS_BY_CLIENTS: 13,
            ARRHES: 14,
            MODIFICATIONS: 15,
            DEFERRED_EXTERNAL_PAYMENTS: 16,
            DEFERRED_EXTERNAL_PAYMENTS_BY_CLIENTS: 17,
            PAUSED_SUBSCRIPTIONS: 18,
        },
    },

    KIOSKS: {
        TYPE: {
            SCHOOL_KIOSK: 1,
            TIME_KIOSK: 2,
            BOOKING_KIOSK: 3,
            PURCHASE_KIOSK: 4,
            PAY_LATER_KIOSK: 5
        },

        ESTABLISHMENTS_FILTER: {
            SHOW_ONLY_KIOSK_ESTABLISHMENT: 1,
            SHOW_PREFILTER_KIOSK_ESTABLISHMENT: 2,
            SHOW_ALL_ESTABLISHMENTS: 3,
        },
    },

    LOCKERS: {
        USER_CODE: {
            MIN_LENGTH: 4,
            MAX_LENGTH: 4,
        },

        MASTER_CODE: {
            MIN_LENGTH: 6,
            MAX_LENGTH: 6,
        },

        LOCKER_STATE: {
            STATE_OPENED: 0,
            STATE_CLOSED: 1,
            STATE_ERROR: 2,
            STATE_UNKNOWN: 3,
        },

        LOCKER_STATUS: {
            STATUS_AVAILABLE: 0,
            STATUS_IN_USE: 1,
            STATUS_PREPARED: 2,
            STATUS_OVERSTAY: 3,
            STATUS_DISABLED: 4,
            STATUS_IN_ERROR: 5,
        },

        LOCKER_ACCESS_MODE: {
            FREE_ACCESS: 0,
            RESERVATION: 1,
            AFFECTATION: 2,
        },

        LOCKER_BOOKING_MODE: {
            MODE_BOOKING: 0,
            MODE_AFFECTATION: 1,
            MODE_SINGLE_USE: 2,
        },

        INCIDENT_TYPE: {
            OTHER_INCIDENT: 0,
            NO_LOCKERS_AVAILABLE_AT_BOOKING_START_INCIDENT: 1,
            NO_LOCKERS_AVAILABLE_AT_BOOKING_RETRY_INCIDENT: 2,
            NOT_OPENED_AT_THE_END_OF_BOOKING_INCIDENT: 3,
            LOW_BATTERY_LOCKER_INCIDENT: 4,
            LOCKER_STATE_NOT_OPENED_INCIDENT: 5,
            LOCKER_STATE_NOT_CLOSED_INCIDENT: 6,
            OFFLINE_GATEWAY_INCIDENT: 7,
            OFFLINE_LOCKER_INCIDENT: 8,
            NON_VALID_MASTER_CODE_LOCKER_INCIDENT: 9,
        },

        BOOKING_STATE: {
            BOOKING_REGISTERED: 0,
            BOOKING_STARTED: 1,
            BOOKING_ENDED: 2,
            BOOKING_CANCELED: 3,
            BOOKING_OVERSTAY: 4,
            BOOKING_ERROR: 5,
            BOOKING_ENDED_WITH_OVERSTAY: 6,
        },

        EVENT_TYPE: {
            TIME_ADVANCE: 3,
            TIME_DELAY: 4,
            MECHANICAL_FAILURE: 9,
            USER_OPENING: 16,
            MASTER_OPENING: 17,
            USER_CLOSING: 18,
            INITIALIZATION: 21,
            AUTOMATIC_OPEN_RENT_TIME: 23,
            NON_VALID_MASTER_CODE: 32,
            TIMED_OUT_CODE: 34,
            OCCUPIED_CARD: 38,
            NON_VALID_CARD: 39,
            USER_OPENING_KEYPAD: 65,
            USER_CLOSING_KEYPAD: 66,
            MASTER_OPENING_KEYPAD: 67,
            MASTER_CLOSING_KEYPAD: 68,
            AUTOMATIC_OPENING: 82,
            MASTER_CLOSING: 83,
            LOW_BATTERY: 84,
            FULL_CARD: 85,
            ERASE_BT: 134,
            STREAMLOR_FREE_CONFIGURATION: 200,
            STREAMLOR_DEDICATED_CONFIGURATION: 201,
            STREAMLOR_MASTER_OPENING: 202,
            STREAMLOR_MASTER_CLOSING: 203,
        },
    },

    STATS: {
        EXPORT_TYPES: {
            SALES_SELLERS: 'sales-sellers',
            SALES_CLIENTS: 'sales-clients',
            SALES_SPA_SERVICES: 'sales-spa-services',
            SALES_SPA_CARES: 'sales-spa-cares',
            SALES_SPA_BOOKINGS: 'sales-spa-bookings'
        }
    },

    RESA_PARAMS: {
        SMSER_GROUP_BY: 'smser_group_by',
        SMSER_OPERATOR: "smser_operator",
        SPA_PLANNINGS_START_HOUR: 'spa_plannings_start_hour',
        SPA_PLANNINGS_START_MIN: 'spa_plannings_start_min',
        SPA_PLANNINGS_END_HOUR: 'spa_plannings_end_hour',
        SPA_PLANNINGS_END_MIN: 'spa_plannings_end_min',
        PLANNING_START_HOUR: 'planning_start_hour',
        PLANNING_START_MIN: 'planning_start_min',
        PLANNING_END_HOUR: 'planning_end_hour',
        PLANNING_END_MIN: 'planning_end_min',
        BOOKING_MARGIN_MINUTES: 'booking_margin_minutes',
        NUMBER_OF_WEEK_VISIBLE: 'number_of_week_visible',
        SLOT_VISIBILITY_UNLOCK_WEEKDAY: 'slot_visibility_unlock_weekday',
        SLOT_VISIBILITY_UNLOCK_TIME: 'slot_visibility_unlock_time',
        CANCELLING_DELAY: 'cancelling_delay',
        LIMIT_TIME_FOR_BOOKING: 'limit_time_for_booking',
        HOURS_BEFORE_IMMEDIATE_BOOKING: 'hours_before_immediate_booking',
        MAX_IMMEDIATE_BOOKINGS_BY_DAY: "max_immediate_bookings_by_day",
        MAX_ACTIVE_BOOKINGS: 'max_active_bookings',
        SUBSCRIPTION_PASSING_DELAY_MINUTES: 'subscription_passing_delay_minutes',
        TIME_PASS_MIN_DEBIT: 'time_pass_min_debit',
        TIME_PASS_MIN_REMAINING: 'time_pass_min_remaining',
        TIME_PASS_MIN_STOP: 'time_pass_min_stop',
        UNIQUE_DEBIT_PER_ZONE: 'unique_debit_per_zone',
        BOOKING_CANCELLATION_DAYS_LIMIT: 'booking_cancellation_days_limit',
        ANONYMIZE_DELAY: 'anonymize_delay',
        REQUIRE_COVID_PASS: 'require_covid_pass',
        DELAY_BETWEEN_RESERVATIONS: 'delay_between_reservations',
        INTERVAL_BETWEEN_GATEWAY_LOOPS: 'interval_between_gateway_loops',
        USERCODE_LENGTH: 'usercode_length',
        ENABLE_LATE_EXIT_PENALTY: 'enable_late_exit_penalty',
        LATE_EXIT_DELAY: 'late_exit_delay',
        LATE_EXIT_BOOKING_TOLERANCE: 'late_exit_booking_tolerance',
        ENABLE_SUB_EXPIRATION_BOOKING_CHECK: 'enable_sub_expiration_booking_check',
        BOOKING_VALIDATES_ENTIRE_DAY: 'booking_validates_entire_day',
        LOCKERS_LED: 'lockers_led',
        COVID_PASSING_DELAY_MINUTES: 'covid_passing_delay_minutes',
        SMSER_AWAITING_RESPONSE_SPA_BOOKINGS: 'smser_awaiting_response_spa_bookings',
        SPA_HUMAN_CONFIRMATION: 'spa_human_confirmation',
        SPA_SHOW_SHORT_LABEL: 'spa_show_short_label',
        PLANNING_SHOW_FILLING: 'planning_show_filling',
        PAYPAL_ENABLED: 'paypal_enabled',
        PAYFIP_ENABLED: 'payfip_enabled',
        STATS_EXPORTS_EXCEL_DISPLAY_NO_DATA: 'stats_exports_excel_display_no_data',
        LOCKERS_UNIQUE_MASTER_CODE: 'lockers_unique_master_code',
        LOCKERS_BUZZER: 'lockers_buzzer',
        LOCKERS_BLOCK_ON_FAILS: 'lockers_block_on_fails',
        LOCKERS_AUTO_OPENING: 'lockers_auto_opening',
        LOCKERS_AUTO_CLOSING: 'lockers_auto_closing',
        LOCKERS_BLOCK_BUZZER: 'lockers_block_buzzer',
        LOCKERS_BLOCK_BLOCK_ON_FAILS: 'lockers_block_block_on_fails',
        LOCKERS_BLOCK_AUTO_OPENING: 'lockers_block_auto_opening',
        LOCKERS_BLOCK_AUTO_CLOSING: 'lockers_block_auto_closing',
        LOCKERS_DELAY_BETWEEN_RESERVATIONS: 'lockers_delay_between_reservations',
        LOCKERS_BLOCK_OPENING_IF_LATE: 'lockers_block_opening_if_late',
        LOCKERS_SCHEDULED_HISTORY_FETCH: 'lockers_scheduled_history_fetch',
        SMSER_SENDER: 'smser_sender',
        SMSER_CUSTOM_MESSAGE_SPA_BOOKINGS: 'smser_custom_message_spa_bookings',
        SMSER_ENDPOINT: 'smser_endpoint',
        SMSER_APPLICATION_KEY: 'smser_application_key',
        SMSER_APPLICATION_SECRET: 'smser_application_secret',
        SMSER_CONSUMER_KEY: 'smser_consumer_key',
        SMSER_SELECTED_SERVICE: 'smser_selected_service',
        MAILER_WEBSITE_NAME: 'mailer_website_name',
        MAILER_WEBSITE_LINK: 'mailer_website_link',
        MAILER_LOGO_URL: 'mailer_logo_url',
        MAILER_FROM: 'mailer_from',
        MAILER_COPYRIGHT: 'mailer_copyright',
        MAIN_DEFAULT_MODULE: 'main_default_module',
        MAILER_CUSTOM_SPA_CREATE: 'mailer_custom_spa_create',
        MAILER_CUSTOM_SPA_MODIFY: 'mailer_custom_spa_modify',
        MAILER_CUSTOM_SPA_DELETE: 'mailer_custom_spa_delete',
        MAILER_CUSTOM_SPA_REMINDER: 'mailer_custom_spa_reminder',
        PAYPAL_LIVE_CLIENT_ID: 'paypal_live_client_id',
        PAYPAL_SANDBOX_CLIENT_ID: 'paypal_sandbox_client_id',
        PAYPAL_LIVE_SECRET_ID: 'paypal_live_secret_id',
        PAYPAL_SANDBOX_SECRET_ID: 'paypal_sandbox_secret_id',
        PAYPAL_MODE: 'paypal_mode',
        PAYFIP_CLIENT_ID: 'payfip_client_id',
        PAYFIP_MODE: 'payfip_mode',
        WEB_ESTABLISHMENT: 'web_establishment',
        LOCKERS_AUTO_OPENING_HOUR: 'lockers_auto_opening_hour',
        PLANNING_DAYS: 'planning_days',
        STATS_SEASON: 'stats_season',
        STATS_PERFORMANCE_TRESHOLDS: 'stats_performance_tresholds',
        LOCKERS_AUTO_OPENING_DAYS: 'lockers_auto_opening_days',
        EXTENDING_VALIDITY_DATE_FOR_SUBSCRIPTIONS: 'extending_validity_date_for_subscriptions',
        BOOKING_OPENING_DATE: 'booking_opening_date',
        BOOKING_TERMINAL_PLANNING_TYPE: 'booking_terminal_planning_type',
        MAILER_CUSTOM_ACTIVITIES_CONFIRM: 'mailer_custom_activities_confirm"',
        MAILER_CUSTOM_ACTIVITIES_CANCEL: 'mailer_custom_activities_cancel',
        SPA_ABSENCES_AMOUNT_WARNING: 'spa_absences_amount_warning',
        QUOTATIONS_PAYMENT_TYPES_CREDIT_CARD: 'quotations_payment_types_credit_card',
        QUOTATIONS_PAYMENT_TYPES_BANK_TRANSFER: 'quotations_payment_types_bank_transfer',
        QUOTATIONS_PAYMENT_TYPES_DEFERRED: 'quotations_payment_types_deferred',
        QUOTATIONS_EXPIRATION_DELAY: 'quotations_expiration_delay',
        QUOTATIONS_SALES_EXPIRATION_DELAY: 'quotations_sales_expiration_delay',
        QUOTATIONS_VOUCHERS_EXPIRATION_DELAY: 'quotations_vouchers_expiration_delay',
    },

    BOOKING_CHECK_CODES: {
        AUTHORIZED: "",
        // time limit to book a slot, after this slot has begun is passed --> params limit_time_for_booking
        TIME_PASSED: "limit_time_passed",
        // the slot on which a booking is being made should not be visible on the planning --> params number_of_week_visible
        SLOT_OUT_OF_VISIBILITY: "slot_out_of_visibility",
        // the client doesn't have a card with an eligible product for the slot
        NO_PRODUCT: "no_product",
        // if the slot is restraint to a group(or groups), the client trying to book the slot doesn't belong to one of the authorized groups
        WRONG_CLIENT_GROUP: "wrong_client_group",
        // if the slot is restraint to a swiming level, the client doesn't have the level necessary
        LEVEL_NOT_GOOD: "level_not_good",
        // the client has reached the limit constraint of bookings
        BOOKING_CHECK_CODE_BOOKINGS_LIMIT_REACHED: "bookings_limit_reached",
        // The client has already booked the slot with success
        ALREADY_BOOKED: "already_booked",
        // No place available
        NO_PLACE_AVAILABLE: "no_place_available",
        // No place available for web booking
        NO_PLACE_AVAILABLE_WEB: "no_place_available_web",
        // Web booking mode not available on a slot
        WEB_MODE_UNAVAILABLE: "web_mode_unavailable",
        // When the client doesn't have the required age for the activity
        INCOMPATIBLE_AGE: "incompatible_age",
        // the wanted client subscription doesn't have enough quantity
        NOT_ENOUGH_QUANTITY: "not_enough_quantity",
        // the subscription is expired or will expire before slot start
        SUBSCRIPTION_EXPIRED: "subscription_expired",
        // When global booking start date not reached
        BOOKING_NOT_OPEN: "booking_not_open",
        // When the activity require a medicert and client doen't have one or one is expired
        MEDICERT_REQUIRED: "medicert_required"
    },

    BOOKING_RESTRICTION_MODES: {
        SLIDING: "sliding", // only future validated bookings are not taken into account
        BLOCKING: "blocking", // taking all validated bookings of the week into account
        GLOBAL: "global" // taking all validated bookings into account
    },

    BOOKING_TERMINAL_PLANNING_TYPES: {
        GROUPED_BY_ACTIVITY: "grouped_by_activity",
        MIXED_ACTIVITIES: "mixed_activities"
    },

    PUDDLE: {
        PAYMENT_RESULTS: {
            SUCCESS: 'success'
        }
    },

    FAMILIES_TYPES: {
        CASHREGISTER: "cashregister",
        KIOSK: "kiosk",
        WEB: "web",
        ACCESS_CONTROL: "access-control",
        STATS: "stats",
        QUOTATION:"quotation"
    },

    CARD_TYPES: {
        QRCODE: "QRCode"
    },

    QUOTATIONS:{
        STATE:{
            DRAFT: 0,
            WAITING: 1,
            VALIDATED: 2,
            REFUSED: 3,
            TO_PAY: 4,
            PAID: 5
        }
    },
    PRICE_ORIGIN:{
        PRICE_ORIGIN_DEFAULT            : 1,
        PRICE_ORIGIN_PRICING            : 2,
        PRICE_ORIGIN_DISCOUNT           : 3,
        PRICE_ORIGIN_MANUAL_PERCENT     : 4,
        PRICE_ORIGIN_MANUAL_PRICE       : 5,
        PRICE_ORIGIN_PRICING_DEGRESSIVE : 6,
        PRICE_ORIGIN_PRORATA            : 7
    }
};
