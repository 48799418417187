import ui, { saleTypes, saleStates } from '../ui/sales'
import helpers from '../helpers/sales'


export default {
    data: () => ({
        saleTypes,
        saleStates
    }),
    methods: {
        ...ui,
        ...helpers
    }
}