import './common'
import axios from 'axios'

export default {
    getTurnstiles: () => axios.get('/ctm/turnstiles'),
    getLogs: (params) => axios.get('/ctm/logs', { params: params }),
    getPassings: (params) => axios.get('/ctm/passings', { params: params }),
    getPassingsExcel: (params) => axios.get('/ctm/passings', { params: params, responseType: 'blob' }),
    postGroups: (group) => axios.post('/ctm/groups', group),
    putGroups: (group) => axios.put(`/ctm/groups/${group.ID}`, group),
    deleteGroups: (group) => axios.delete(`/ctm/groups/${group.ID}`, group)
}
