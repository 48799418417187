import './common'
import axios from 'axios'

export default {
    // Setups
    syncAllStats: (params) => axios.get('/stats/sync-all-stats', { params: params }),
    deleteAllStats: (params) => axios.get('/stats/delete-all-stats', { params: params }),
    resetAllStats: (params) => axios.get('/stats/reset-all-stats', { params: params }),
    refreshAllStats: (params) => axios.get('/stats/refresh-all-stats', { params: params }),

    getSyncAllStatsTimetables: (params) => axios.get('/stats/setup/sync-timetables-import', { params: params }),
    getNewSyncAllStatsTimetables: (params) => axios.get('/stats/setup/sync-timetables-refresh', { params: params }),
    getSyncAllStatsTimetablesFormatted: (params) => axios.get('/stats/setup/sync-timetables-formatted', { params: params }),

    // Predictions
    getPredictionsPassings: (params) => axios.get('/stats/predictions/passings', { params }),

    // Dashboard
    getStatsDashboardLayouts: (params) => axios.get('/stats/dashboard/layout', { params }),
    upsertStatsDashboardLayout: (data, params) => axios.post('/stats/dashboard/layout', data, { params }),

    // Utils
    getStatsResaYearsWithData: (params) => axios.get('/stats-resa/years-with-data', { params: params }),
    getStatsResaFirstYearWithData: (params) => axios.get('/stats-resa/years-with-data/first', { params: params }),
    getStatsResaLastYearWithData: (params) => axios.get('/stats-resa/years-with-data/last', { params: params }),

    // Passings
    getStatsPassingsFrequentations: (params) => axios.get('/stats/passings/frequentations', { params: params }),
    getStatsPassingsPlannings: (params) => axios.get('/stats/passings/plannings', { params: params }),

    getStatsPassingsByProductTypeExcelGlobal: (params) => axios.get('/stats/passings/excel/global/by-product-type', { params: params, responseType: 'blob' }),
    getStatsPassingsByProductExcelGlobal: (params) => axios.get('/stats/passings/excel/global/by-product', { params: params, responseType: 'blob' }),
    getStatsPassingsByActivityExcelGlobal: (params) => axios.get('/stats/passings/excel/global/by-activity', { params: params, responseType: 'blob' }),
    postStatsPassingsExcelClientsDataTable: (data) => axios.post('/stats/passings/excel/clients-data-table', data, { responseType: 'blob' }),

    // Sales
    getStatsSalesPeriodicReport: (params) => axios.get('/stats/sales/periodic-report', { params: params }),

    // Spa
    getSoldProductsPeriodicReport: (params) => axios.get('/stats/spa/periodic-report', { params: params }),

    /// RESA
    // Descriptives : Totaux

    /// ///////////////////////////////////////      Pre-select Filters     ///////////////////////////////////////////////////////

    // All resa
    selectSavedFiltersResaNames: (params) => axios.get('/stats-resa/saved-filters/resa/select/names', { params: params }),
    selectSavedFiltersResa: (params) => axios.get('/stats-resa/saved-filters/resa/select', { params: params }),
    insertSavedFilterResa: (data) => axios.post('/stats-resa/saved-filters/resa/insert', data),
    updateSavedFilterResa: (data) => axios.post('/stats-resa/saved-filters/resa/update', data),
    deleteSavedFilterResa: (params) => axios.get('/stats-resa/saved-filters/resa/delete', { params: params }),

    /// ///////////////////////////////////////      Fréquentations     ///////////////////////////////////////////////////////

    getStatsResaFrequentationsParActivite: (params) => axios.get('/stats-resa/frequentations/par-activite', { params: params }),

    /// ///////////////////////////////////////      Bilans Périodiques     ///////////////////////////////////////////////////////
    // Réservations
    getStatsResaDescriptivesProportionsTypesResa: (params) => axios.get('/stats-resa/bilans-periodiques/reservations/proportions-types-resa', { params: params }),

    // Activités
    getStatsResaDescriptivesActivitiesData: (params) => axios.get('/stats-resa/bilans-periodiques/activites/activities-data', { params: params }),

    // Clients
    getStatsResaDescriptivesClientsAge: (params) => axios.get('/stats-resa/bilans-periodiques/clients/activities-by-age', { params: params }),

    // Articles

    // Bookings Slots
    getBookingsSlotsDetailled: (params) => axios.get('/stats-resa/bilans-periodiques/bookings-slots/detailled', { params: params }),
    getBookingsSlotsCount: (params) => axios.get('/stats-resa/bilans-periodiques/bookings-slots/count', { params: params }),

    // Trainers
    getTrainersDetailled: (params) => axios.get('/stats-resa/bilans-periodiques/trainers/detailled', { params: params }),

    /// ///////////////////////////////////////      Plannings     ///////////////////////////////////////////////////////

    // Plannings CR
    getStatsResaPlanningsCRByDateRange: (params) => axios.get('/stats-resa/plannings/cr/by-date-range', { params: params })

}
