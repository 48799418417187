import axios from 'axios'
import store from '~/store/store'
import router from '~/router/router'
import Config from '~/config'
import EventBus from '~/eventbus'

axios.defaults.baseURL = Config.BaseURL

axios.interceptors.request.use(function (config) {
    if (store.getters.isLoggedIn) {
        if (config.headers.common) {
            config.headers.common.Authorization = store.getters.authToken
        } else {
            config.headers.common = { Authorization: store.getters.authToken }
        }
    }

    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (res) {
    return res
}, function (res) {
    if (res.response.status >= 500) {
    // Notification.error({title: 'Erreur ' + res.response.status, message: 'Une erreur serveur est survenue, veuillez réessayer ultérieurement'});
    } else if (res.response.status === 401) {
        if (store.getters.isLoggedIn) {
            // Token likely expired, clear authentication
            store.dispatch('logout')
            EventBus.$emit('toast-message', { type: 'error', message: 'Votre session a expiré, veuillez vous reconnecter' })

            if (global.cashRegisterIPC && global.cashRegisterIPC.emit) {
                global.cashRegisterIPC.emit('auth-error')
            }
        }

        router.push('/').catch(() => {})
    }

    return Promise.reject(res)
})

export default {}
