import subscriptionHelper from './subscriptions'
import {
    clientsGenders
} from '../ui/client'


export default {
    clientsGender(x) {
        return clientsGenders.filter(elem => elem.value == x)
    },

    clientFullname(client) {
        if (!client) {
            return ''
        }

        if (client.OrgInfo && client.OrgInfo.Name) {
            if (client.Firstname || client.Lastname) {
                return client.OrgInfo.Name + ' (' + client.Firstname + ' ' + client.Lastname + ')'
            }

            return client.OrgInfo.Name
        }

        return client.Firstname + ' ' + client.Lastname
    },

    getCards(client) {
        const cards = []
        if (!client || !client.Cards) {
            return cards;
        }

        client.Cards.forEach(el => {
            if (el.CardNumber) {
                for (let i = 0; i < el.CardNumber.length; i++) {
                    cards.push({
                        CardNumber: el.CardNumber[i],
                        AttributionDate: el.AttributionDate[i],
                        CardType: el.CardType,
                        Subscriptions: el.Subscriptions
                    })
                }
            }
        })
        return cards
    },

    clientHasCardType(client, cardType){
        return client && client.Cards && client.Cards.some(c => c.CardType == cardType && c.CardNumber && c.CardNumber.length > 0)
    },

    clientHasCardTypeCompatibleForProduct(client, product){
         return product.Subscription && 
         product.Subscription.CardType && 
         client && 
         client.Cards && 
         client.Cards.some(c =>  product.Subscription.CardType.includes(c.CardType) && c.CardNumber && c.CardNumber.length > 0)
    },


    getSubscriptions(client, products) {
        const out = []
        if (!client || !client.Cards) {
            return out;
        }

        products = products === undefined ? [] : products
        var product
        if (client.Cards) {
            client.Cards.forEach(el => {
                if (el.Subscriptions){
                    el.Subscriptions.forEach(s => {
                        product = Array.isArray(products) ? products.find(p => p.ID === s.ProductID) : products[s.ProductID]
                        out.push(Object.assign(s, { Product: product }))
                    })
                }
            })
        }
        return out
    },

    clientHasValidSubscriptionWithProductID(client, productID, productsMap) {
        if (!client.Cards) {
            return
        }

        return client.Cards.some(c => c.Subscriptions && c.Subscriptions.some(s => s.ProductID === productID && subscriptionHelper.isSubscriptionValid(s, productsMap)))
    },

    getPricing(client, currentPeriod, pricing) {
        if (pricing && currentPeriod && client) {
            let pricingId
            if (client.Pricing && client.Pricing[currentPeriod.ID]) {
                pricingId = client.Pricing[currentPeriod.ID]
            } else {
                pricingId = 'DEFAULT'
            }
            return pricing.find(p => p.ID === pricingId)
        }
        return false
    },

    clientHasRequiredLevelForSlot (client, slot) {
        if (!slot.Levels || slot.Levels.length === 0) {
            return true
        }
        return client.ActivitiesLevels && client.select.ActivitiesLevels.some(l => l.ActivityID === slot.ActivityID.ID && slot.Levels.includes(l.LevelID))
    },
}
