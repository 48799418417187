import './common'
import axios from 'axios'

export default {
    scanVoucher: (id) => axios.get(`/vouchers/${id}/scan`),
    validateVoucher: (data) => axios.post(`/vouchers/${data.VoucherID}/validate`, data),
    getGiftVouchers: (params) => axios.get('/vouchers-gift', { params }),
    getGiftVouchersExcel: (params) => axios.get('/vouchers-gift', { params: params, responseType: 'blob' }),
    downloadVoucher: (id, params) => axios.get(`/vouchers/${id}/pdf`, { params, responseType: 'blob' }),
    updateVoucher: (voucher) => axios.put(`/vouchers/${voucher.ID}`, voucher),
    massPushVouchers: (data) => axios.post('/vouchers-mass-push', data),
    postSheets: (data) => axios.post('/vouchers-sheets', data),
    putSheet: (id, data) => axios.put(`/vouchers-sheets/${id}`, data),
    getSheets: (params) => axios.get('/vouchers-sheets', { params }),
    getPaginatedSheets: (params) => axios.get('/vouchers-sheets-paginated', { params }),
    getSheet: (id, params) => axios.get(`/vouchers-sheets/${id}`, { params }),
    getSheetsPdf: (id, params) => axios.get(`/vouchers-sheets/${id}/pdf`, { params, responseType: 'blob' }),
    postSheetsPdfPreview: (data, params) => axios.post('/vouchers-sheets-preview/pdf', data, { params, responseType: 'blob' }),
    getSheetsExcel: (id, params) => axios.get(`/vouchers-sheets/${id}/excel`, { params, responseType: 'blob' }),
    getSheetsInvoice: (id, params) => axios.get(`/vouchers-sheets/${id}/invoice`, { params }),
    postVoucherSale: (data) => axios.post('/vouchers-sale', data)
}
