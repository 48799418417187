import Consts from '../consts'

const SALE_TYPE_AVOIR = -1;
const SALE_TYPE_CORRECTION = -2

const saleStates = [
    { label: 'Brouillon', value: Consts.SALE_STATE_DRAFT, icon: 'mdi-file', color: '#9c9c9c' },
    { label: 'En pause', value: Consts.SALE_STATE_PAUSED, icon: 'mdi-pause', color: '#9c9c9c' },
    { label: 'En attente de paiement', value: Consts.SALE_STATE_PAYING, icon: 'mdi-credit-card-clock', color: '#9c9c9c' },
    { label: 'En attente de confirmation', value: Consts.SALE_STATE_READY_CONFIRMED, icon: 'mdi-pause', color: '#9c9c9c' },
    { label: 'Confirmée', value: Consts.SALE_STATE_CONFIRMED, icon: 'mdi-check', color: '#50c246' },
    { label: 'Annulée', value: Consts.SALE_STATE_CANCELLED, icon: 'mdi-cancel', color: '#e34242' },
    { label: 'Paiement en pause', value: Consts.SALE_STATE_PAUSED_PAYING, icon: 'mdi-credit-card-clock', color: '#9c9c9c' },
    { label: 'En attente de paiement', value: Consts.SALE_STATE_PAYMENT_PENDING, icon: 'mdi-credit-card-clock', color: '#9c9c9c' },
    { label: 'Arrhes', value: Consts.SALE_STATE_ARRHES, icon: 'mdi-credit-card-settings', color: '#9c9c9c' },
]

// Deprecared: use saleStates
const saleTypes = [
    { label: 'Vente confirmée', value: Consts.SALE_STATE_CONFIRMED },
    { label: 'Arrhes', value: Consts.SALE_STATE_ARRHES },
    { label: 'Avoir', value: SALE_TYPE_AVOIR},
    { label: 'Correction', value: SALE_TYPE_CORRECTION },
    { label: 'Vente non payée', value: Consts.SALE_STATE_PAYMENT_PENDING },
]


export default {
    saleType(sale) {
        if (sale.CreditNote && !sale.Correction) {
            return saleTypes.find(t => t.value == SALE_TYPE_AVOIR)
        } else if (sale.Correction) {
            return saleTypes.find(t => t.value == SALE_TYPE_CORRECTION)
        } else if (!sale.CreditNote && sale.State === Consts.SALE_STATE_PAYMENT_PENDING) {
            return saleTypes.find(t => t.value == Consts.SALE_STATE_PAYMENT_PENDING)
        } else if (!sale.CreditNote && sale.State === Consts.SALE_STATE_ARRHES) {
            return saleTypes.find(t => t.value == Consts.SALE_STATE_ARRHES)
        } else if (!sale.CreditNote && sale.State !== Consts.SALE_STATE_PAYMENT_PENDING && sale.State !== Consts.SALE_STATE_ARRHES) {
            return saleTypes.find(t => t.value == Consts.SALE_STATE_CONFIRMED)
        }
    },

    saleState(state){
        return saleStates.find(s => s.value == state)
    }
}

export {saleTypes, saleStates}