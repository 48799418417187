import './common'
import axios from 'axios'

export default {
    // SPA Universes
    getSpaUniverses: (params) => axios.get('/spa/universes', { params: params }),
    getSpaUniverse: (id) => axios.get(`/spa/universes/${id}`),
    createSpaUniverse: (data) => axios.post('/spa/universes', data),
    updateSpaUniverse: (id, data) => axios.put(`/spa/universes/${id}`, data),

    // SPA Resources
    getSpaResources: (params) => axios.get('/spa/resources', { params: params }),
    getSpaResource: (id) => axios.get(`/spa/resources/${id}`),
    createSpaResource: (data) => axios.post('/spa/resources', data),
    updateSpaResource: (id, data) => axios.put(`/spa/resources/${id}`, data),
    updateSpaResourceActivities: (id, data) => axios.put(`/spa/resources/${id}/activities`, data),

    // SPA Personnels
    getSpaPersonnels: (params) => axios.get('/spa/personnels', { params: params }),
    getSpaPersonnel: (id) => axios.get(`/spa/personnels/${id}`),
    createSpaPersonnel: (data) => axios.post('/spa/personnels', data),
    updateSpaPersonnel: (id, data) => axios.put(`/spa/personnels/${id}`, data),
    updateSpaPersonnelActivities: (id, data) => axios.put(`/spa/personnels/${id}/activities`, data),

    // SPA Personnels unavailabilities
    getSpaPersonnelsUnavailabilities: (params) => axios.get('/spa/unavailabilities', { params: params }),
    createSpaPersonnelUnavailability: (data) => axios.post('/spa/unavailabilities', data),
    updateSpaPersonnelUnavailability: (id, data) => axios.put(`/spa/unavailabilities/${id}`, data),
    deleteSpaPersonnelUnavailability: (id) => axios.delete(`/spa/unavailabilities/${id}`),
    deleteSpaPersonnelUnavailabilityMass: (data) => axios.post('/spa/unavailabilities-mass', data),
    getSpaUnavailabilitiesModels: (params) => axios.get('/spa/unavailabilities-models', { params: params }),
    createSpaUnavailabilityModel: (data) => axios.post('/spa/unavailabilities-models', data),
    updateSpaUnavailabilityModel: (id, data) => axios.put(`/spa/unavailabilities-models/${id}`, data),
    deleteSpaUnavailabilityModel: (id) => axios.delete(`/spa/unavailabilities-models/${id}`),
    createSpaApplyUnavailabilityModel: (data) => axios.post('/spa/apply-unavailability-model', data),

    // SPA Activities
    getSpaActivities: (params) => axios.get('/spa/activities', { params: params }),
    getSpaActivity: (id) => axios.get(`/spa/activities/${id}`),
    createSpaActivity: (data) => axios.post('/spa/activities', data),
    updateSpaActivity: (id, data) => axios.put(`/spa/activities/${id}`, data),

    // SPA ActivityFamilies
    getSpaActivityFamilies: (params) => axios.get('/spa/activity-families', { params: params }),
    createSpaActivityFamily: (data) => axios.post('/spa/activity-families', data),
    updateSpaActivityFamily: (id, data) => axios.put(`/spa/activity-families/${id}`, data),
    deleteSpaActivityFamily: (id) => axios.delete(`/spa/activity-families/${id}`),

    // SPA Products
    getSpaProducts: (params) => axios.get('/spa/products', { params: params }),
    getSpaProduct: (id) => axios.get(`/spa/products/${id}`),
    createSpaProduct: (data) => axios.post('/spa/products', data),
    updateSpaProduct: (id, data) => axios.put(`/spa/products/${id}`, data),

    // SPA Booking
    getSpaBookings: (params) => axios.get('/spa/bookings', { params: params }),

    updateSpaBooking: (id, data) => axios.put(`/spa/bookings/${id}`, data),
    sendSpaBookingNotification: (id, type) => axios.post(`/spa/bookings/${id}/notification/${type}`),
    getClientsWithUnplacedSpaBookings: (params) => axios.get('/spa/bookings/unplaced/clients', { params: params }),

    // SPA Sales
    createSpaSale: (data) => axios.post('/spa/sales', data),
    getSpaPendingSales: (params) => axios.get('/spa/sales/pending', { params: params }),
    getSpaPendingProducts: (params) => axios.get('/spa/sales/pending-products', { params: params }),
    enableSpaPendingSale: (data) => axios.post('/spa/sales/enable', data),
    deleteSpaSale: (id) => axios.delete(`/spa/sales/${id}`)
}
