export default [
    {
        path: '/spa',
        name: 'spa-index',
        meta: {
            title: 'Spa'
        },
        redirect: { name: 'spa-planning' },
        component: () => import('~/views/spa/Index.vue'),
        children: [
            {
                path: 'planning',
                name: 'spa-planning',
                meta: {
                    title: 'Planning Spa'
                },
                component: () => import('~/views/spa/planning/Planning.vue')
            },
            {
                path: 'personnels',
                name: 'spa-personnels',
                meta: {
                    title: 'Gestion des personnels Spa'
                },
                component: () => import('~/views/spa/Personnel.vue')
            },
            {
                path: 'personnels/:id',
                name: 'spa-personnels-edit',
                meta: {
                    title: 'Edition d\'un employé'
                },
                component: () => import('~/views/spa/PersonnelsEdit.vue')
            },
            {
                path: 'resources',
                name: 'spa-resources',
                meta: {
                    title: 'Gestion des ressources Spa'
                },
                component: () => import('~/views/spa/Resources.vue')
            },
            {
                path: 'resources/:id',
                name: 'spa-resources-edit',
                meta: {
                    title: 'Edition d\'une ressource'
                },
                component: () => import('~/views/spa/ResourcesEdit.vue')
            },
            {
                path: 'activities',
                name: 'spa-activities',
                meta: {
                    title: 'Gestion des activités Spa'
                },
                component: () => import('~/views/spa/Activities.vue')
            },
            {
                path: 'activities/:id',
                name: 'spa-activities-edit',
                meta: {
                    title: 'Edition d\'une activité'
                },
                component: () => import('~/views/spa/ActivitiesEdit.vue')
            },
            {
                path: 'products',
                name: 'spa-products',
                meta: {
                    title: 'Produits Spa'
                },
                component: () => import('~/views/spa/Products.vue')
            },
            {
                path: 'products/:id',
                name: 'spa-products-edit',
                meta: {
                    title: 'Edition d\'une association produit'
                },
                component: () => import('~/views/spa/ProductsEdit.vue')
            }
        ]
    }
]
