import './common'
import axios from 'axios'

export default {
    getProducts: (params) => axios.get('/products', { params: params }),
    getProductsStats: (params) => axios.get('/products-stats', { params: params }),
    getProductsByIDs: (params) => axios.get('/products-by-ids', { params: params }),
    getPaginatedProducts: (params) => axios.get('/products-paginated', { params: params }),
    getFamilies: (params) => axios.get('/families', { params: params }),
    getCardTypes: () => axios.get('/card-types')
}
