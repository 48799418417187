export default [
    {
        path: '/stats',
        name: 'stats-index',
        meta: { title: 'Statistiques' },
        component: () => import('@/views/stats/Index.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'stats-dashboard',
                meta: { title: 'Tableau de bord' },
                component: () => import('@/views/stats/Dashboard.vue'),
                redirect: 'dashboard/layout/default',
                children: [
                    {
                        path: 'layout/:id',
                        name: 'stats-dashboard-layout',
                        meta: { title: 'Mon tableau de bord' },
                        component: () => import('@/views/stats/dashboard/Layout.vue')
                    },
                    {
                        path: 'activities',
                        name: 'stats-dashboard-activities',
                        meta: { title: 'Activités' },
                        component: () => import('@/views/stats/dashboard/Activities.vue')
                    },
                    {
                        path: 'attendance',
                        name: 'stats-dashboard-attendance',
                        meta: { title: 'Créneaux & Fréquentation' },
                        component: () => import('@/views/stats/dashboard/Attendance.vue')
                    },
                    {
                        path: 'passings',
                        name: 'stats-dashboard-passings',
                        meta: { title: 'Entrées' },
                        component: () => import('@/views/stats/dashboard/Passings.vue')
                    },
                    {
                        path: 'sales',
                        name: 'stats-dashboard-sales',
                        meta: { title: 'Ventes' },
                        component: () => import('@/views/stats/dashboard/Sales.vue')
                    }
                ]
            },
            {
                path: 'activity',
                name: 'stats-activity',
                meta: { title: 'Analyse d\'une activité' },
                component: () => import('@/views/stats/Activity.vue')
            },
            {
                path: 'activity/compare',
                name: 'stats-activity-compare',
                meta: { title: 'Comparaison d\'activités' },
                component: () => import('@/views/stats/Activities.vue')
            },
            {
                path: 'passings',
                name: 'stats-passings',
                meta: { title: 'Analyse des entrées' },
                component: () => import('@/views/stats/Passings.vue')
            },
            {
                path: 'sales',
                name: 'stats-sales',
                meta: { title: 'Ventes' },
                component: () => import('@/views/stats/Sales.vue')
            },
            {
                path: 'predictions',
                name: 'stats-predictions',
                meta: { title: 'Prévisions' },
                component: () => import('@/views/stats/Predictions.vue')
            }
        ]
    }
]
