import moment from 'moment'

export default {
    methods: {
        formatDate (d) {
            return moment(d).format('DD/MM/YYYY')
        },
        formatDateUS (d) {
            return moment(d).format('YYYY-MM-DD')
        },
        formatDateCustom (d, layout) {
            return moment(d).format(layout)
        },
        formatBirthDate (date) {
            const parsedDate = moment(date)
            if (!parsedDate.isValid() || parsedDate.year() <= 1901) {
                return ''
            } else {
                return parsedDate.format('DD/MM/YYYY')
            }
        },
        formatDateTime (d) {
            return moment(d).format('DD/MM/YYYY HH:mm')
        },
        formatDateTimeSeconds (d) {
            return moment(d).format('DD/MM/YYYY HH:mm:ss')
        },
        formatDateHuman (d) {
            return moment(d).format('ddd DD MMM YYYY')
        },
        formatDateWeek (d) {
            return moment(d).format('MMM YYYY ([S]WW)')
        },
        formatHumanBirthDate (date) {
            const parsedDate = moment(date)
            if (!parsedDate.isValid() || parsedDate.year() <= 1901) {
                return ''
            } else {
                return this.formatDateHuman(date)
            }
        },
        formatDateTimeHuman (d) {
            return moment(d).format('ddd DD MMM YYYY, HH:mm')
        },
        formatTime (d) {
            return moment(d).format('HH:mm')
        },
        formatTimeSecs (d) {
            return moment(d).format('HH:mm:ss')
        },
        formatWeekNumber (d) {
            return moment(d).format('W')
        },
        parseDate (d) {
            const date = moment(d, 'DD/MM/YYYY')
            if (date.isValid()) {
                return date.format('YYYY-MM-DD')
            } else {
                return ''
            }
        },
        prevDay (d) {
            return moment(d).subtract(1, 'days').format('YYYY-MM-DD')
        },
        nextDay (d) {
            return moment(d).add(1, 'days').format('YYYY-MM-DD')
        },
        prevWeek (d) {
            return moment(d).subtract(1, 'week').format('YYYY-MM-DD')
        },
        nextWeek (d) {
            return moment(d).add(1, 'week').format('YYYY-MM-DD')
        },
        isThisHour (hour) {
            return parseInt(moment().format('HH'), 10) === parseInt(hour, 10)
        },
        isToday (date) {
            return moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        },
        dateNow () {
            return moment().format('YYYY-MM-DD')
        },
        timeNow () {
            return moment().format('HH:mm')
        },
        isFuture (d) {
            return moment(d).isSameOrAfter(moment())
        },
        getAge (date) {
            const parsedDate = moment(date)
            if (!parsedDate.isValid() || parsedDate.year() <= 1901) {
                return ''
            } else {
                return moment().diff(parsedDate, 'years')
            }
        },

        getWeekDayName (date, capitalize) {
            let d = moment(date).format('dddd')
            if (capitalize) {
                d = d.charAt(0).toUpperCase() + d.slice(1)
            }
            return d
        },
        getWeekDayNumber (date) {
            return moment(date).day()
        },

        getWeekNumber (date) {
            return moment(date).week()
        },
        compareDate (d1, d2) {
            return moment(d1).isSame(moment(d2)) ? 0 : moment(d1).isBefore(moment(d2)) ? -1 : 1
        }
    }
}
