import ActivitiesApi from '~/api/activities'
import AccountsApi from '~/api/accounts'
import _ from 'lodash'
import Vue from 'vue'

const initialState = () => ({
    /* remote */
    planningActivities: [],
    planningActivityFamilies: [],
    trainers: [],
    levels: []
    /* local */
})

export default {
    state: initialState,
    getters: {
        planningActivities: state => state.planningActivities,
        planningActivityFamilies: state => state.planningActivityFamilies,
        trainers: state => state.trainers,
        levels: state => state.levels
    },
    actions: {
        getPlanningActivities ({ commit }) {
            return new Promise((resolve, reject) => {
                ActivitiesApi.getActivities().then(res => {
                    commit('set_activities', _.keyBy(res.data ?? [], 'ID'))
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getPlanningActivityFamilies ({ commit }) {
            return new Promise((resolve, reject) => {
                ActivitiesApi.getActivitiesFamilies().then(res => {
                    commit('set_activity_families', _.keyBy(res.data ?? [], 'ID'))
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getTrainers ({ commit }) {
            return new Promise((resolve, reject) => {
                AccountsApi.getTrainers().then(res => {
                    if (res.data) {
                        res.data.forEach(train => {
                            Vue.set(train, 'Fullname', `${train.Firstname} ${train.Lastname}`)
                        })
                        commit('set_trainers', _.keyBy(res.data ?? [], 'ID'))
                    } else {
                        commit('set_trainers', {})
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getLevels ({ commit }) {
            return new Promise((resolve, reject) => {
                ActivitiesApi.getLevels().then(res => {
                    if (res.data) {
                        commit('set_levels', _.keyBy(res.data ?? [], 'ID'))
                    } else {
                        commit('set_levels', {})
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    },
    mutations: {
        set_activities (state, val) {
            state.planningActivities = val
        },
        set_activity_families (state, val) {
            state.planningActivityFamilies = val
        },
        set_trainers (state, val) {
            state.trainers = val
        },
        set_levels (state, val) {
            state.levels = val
        }
    }
}
